import {
    usePatchUserSFContactMutation,
    useDeleteGuardianOrPatientFromIdMutation,
  } from "../redux/services/usersApi";
  import { toast } from "react-toastify";
  import { useTranslation } from "react-i18next";
  
  export const useDeleteGuardianAndPatient = () => {
    const { t } = useTranslation();
    const [patchSfAccount] = usePatchUserSFContactMutation();
    const [removeGuardianOrPatient] = useDeleteGuardianOrPatientFromIdMutation();
  
    const deleteGuardianAndPatient = async (
      id: number,
      relationshipType: "guardian" | "patient" | "both"
    ) => {
      const resultDeleteRoleFromOtherUser = await removeGuardianOrPatient({
        id: id?.toString(),
        data: {
          idType: "userId",
          relationshipType,
        },
      });
  
      if ("data" in resultDeleteRoleFromOtherUser) {
        const guardianUser = resultDeleteRoleFromOtherUser?.data?.guardianUser;
        const patientUser = resultDeleteRoleFromOtherUser?.data?.patientUser;
  
        if (guardianUser) {
          const id = guardianUser?.id;
          const settingsJSON = guardianUser?.settings as unknown;
          if (typeof settingsJSON === "string") {
            const settings = JSON.parse(settingsJSON);
  
            await patchSfAccount({
              userId: id?.toString(),
              data: { ...settings },
            });
          }
        }
  
        if (patientUser) {
          const id = patientUser?.id;
          const settingsJSON = patientUser?.settings as unknown;
          if (typeof settingsJSON === "string") {
            const settings = JSON.parse(settingsJSON);
  
            await patchSfAccount({
              userId: id?.toString(),
              data: { ...settings },
            });
          }
        }
  
        if (!guardianUser && !patientUser) {
          return null;
        }
  
        const roleContent = resultDeleteRoleFromOtherUser?.data?.user?.settings?.[
          "Osoba_chora__c"
        ]
          ? "opiekuna"
          : "podopiecznego";
        toast.success(
          `${t(
            "toast_guardiansAndPatientsPanel_delete_role_success"
          )} ${roleContent}`
        );
        return resultDeleteRoleFromOtherUser.data;
      } else {
        handleErrors(resultDeleteRoleFromOtherUser);
      }
    };
  
    const handleErrors = (result: any) => {
      if ("error" in result) {
        const error = result?.error as TranslationSubmitError;
        switch (error.data.statusCode) {
          case 500:
            toast.error(
              t("toast_guardiansAndPatientsPanel_delete_role_error_500")
            );
            break;
          case 404:
            toast.error(`Nie znaleziono użytkownika o takim ID.`);
            break;
          case 400:
            toast.error(error.data.message);
            break;
          default:
            toast.error(t("toast_guardiansAndPatientsPanel_delete_role_error"));
            break;
        }
      }
    };
  
    return deleteGuardianAndPatient;
  };
import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SubmitHandler, useForm } from "react-hook-form"
import { Button, Container, Form } from "react-bootstrap"
import styles from "./VerificationTemplate.module.scss"
import {
  useDeleteUsersVerifyMutation,
  usePatchUsersVerifyLoggedMutation,
  usePatchUsersVerifyMutation,
  UserVerifyTypeEnum,
  usePatchUserSFContactMutation,
} from "../../../redux/services/usersApi"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store/index"
import TooltipIcon from "../../atoms/TooltipIcon"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import Landing, { domain } from "../../organisms/Landing/Landing"
import { NAV_PATH } from "components/organisms/Navigation/navigationData"

export const VerificationTemplate: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { isLogged } = useSelector((state: RootState) => state.user)

  const [patchUsersVerify] = usePatchUsersVerifyMutation()
  const [patchUsersVerifyLogged] = usePatchUsersVerifyLoggedMutation()
  const [deleteUsersVerify] = useDeleteUsersVerifyMutation()
  const [patchSfAccount] = usePatchUserSFContactMutation()

  const [buttonClicked, setButtonClicked] = useState<
    "accept" | "delete" | null
  >(null)
  const [textType, setTextType] = useState("")

  useEffect(() => {
    switch (searchParams?.get("type")) {
      case UserVerifyTypeEnum.COMPANY_USER:
        return setTextType("Pracownik firmy")
      case UserVerifyTypeEnum.PATIENT_GUARDIAN:
        return setTextType("Opiekun pacjenta")
      case UserVerifyTypeEnum.GUARDIAN_PATIENT:
        return setTextType("Podopieczny opiekuna")
      case UserVerifyTypeEnum.DOCTOR_FACILITY:
        return setTextType("Placówkę doktora")
      case UserVerifyTypeEnum.FACILITY_DOCTOR:
        return setTextType("Doktorowi placówkę")
      default:
        setTextType("Błąd")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("type")])

  const { handleSubmit, watch } = useForm<VerificationForm>({
    defaultValues: {
      code: searchParams?.get("code") || "",
      type: searchParams?.get("type") || "",
      isEmail: !!searchParams?.get("isEmail"),
    },
  })

  const [content, setContent] = useState<React.ReactNode>(
    <>
      <Button
        type="submit"
        value="Submit"
        className={"btn-outline-dark-blue ls-3 "}
        onClick={() => setButtonClicked("delete")}
      >
        ODRZUĆ ZAPROSZENIE
      </Button>
      <TooltipIcon
        desc={
          watch("isEmail") && !isLogged
            ? "Musisz być zalogowany, aby zaproszenie zostało przypisane do twojego konta"
            : ""
        }
      >
        <Button
          disabled={watch("isEmail") && !isLogged}
          type="submit"
          value="Submit"
          className={`ls-3 ${watch("isEmail") && !isLogged && "w-100"}`}
          onClick={() => setButtonClicked("accept")}
        >
          PRZYJMIJ ZAPROSZENIE
        </Button>
      </TooltipIcon>
    </>,
  )

  const loginHandler = () => {
    const redirectUrl = `${domain}/api/v1/login?redirectUrl=${domain}/${NAV_PATH.DASHBOARD}`
    window.location.replace(redirectUrl)
  }

  const PATIENT_GUARDIAN_TARGET_SETTING: string = "Wskazanie_na_opiekuna__r"
  const GUARDIAN_PATIENT_TARGET_SETTING: string = "Wskazanie_podopiecznego__r"

  const isGuardianType = watch("type") === UserVerifyTypeEnum.PATIENT_GUARDIAN
  const isPatientType = watch("type") === UserVerifyTypeEnum.GUARDIAN_PATIENT

  const submitHandler: SubmitHandler<VerificationForm> = async (data) => {
    if (buttonClicked === "accept") {
      let hasBeenAccept
      if (data.isEmail) {
        hasBeenAccept = await patchUsersVerifyLogged({ data })
      } else {
        hasBeenAccept = await patchUsersVerify({ data })
      }

      if (
        hasBeenAccept &&
        "data" in hasBeenAccept &&
        hasBeenAccept?.data?.meta?.status === "success"
      ) {
        if (isGuardianType) {
          await patchSfAccount({
            userId: hasBeenAccept?.data?.data?.accept?.patient?.toString(),
            data: {
              [PATIENT_GUARDIAN_TARGET_SETTING]: {
                Email: hasBeenAccept?.data?.data?.accept?.guardianId?.email,
              },
            },
          })
          await patchSfAccount({
            userId: hasBeenAccept?.data?.data?.accept?.guardian?.toString(),
            data: {
              [GUARDIAN_PATIENT_TARGET_SETTING]: {
                Email: hasBeenAccept?.data?.data?.accept?.patientId?.email,
              },
            },
          })
        }
        if (isPatientType) {
          await patchSfAccount({
            userId: hasBeenAccept?.data?.data?.accept?.guardian?.toString(),
            data: {
              [GUARDIAN_PATIENT_TARGET_SETTING]: {
                Email: hasBeenAccept?.data?.data?.accept?.patientId?.email,
              },
            },
          })
          await patchSfAccount({
            userId: hasBeenAccept?.data?.data?.accept?.patient?.toString(),
            data: {
              [PATIENT_GUARDIAN_TARGET_SETTING]: {
                Email: hasBeenAccept?.data?.data?.accept?.guardianId?.email,
              },
            },
          })
        }
      }

      if ("data" in hasBeenAccept) {
        if (hasBeenAccept?.data?.meta?.status === "success") {
          toast.success(t("toast_verificationTemplate_success"))
          if (isLogged) {
            navigate(`/${NAV_PATH.DASHBOARD}`)
          } else {
            setContent(
              <div
                className={`border alivia-radius border-1 border-primary fw-700 w-100 px-3 py-4 text-center mb-5`}
              >
                <p className={"m-0"}>
                  Zaproszenie zostało zaakceptowane.{" "}
                  <span
                    className={"text-primary cursor-pointer"}
                    onClick={loginHandler}
                  >
                    {isGuardianType || isPatientType
                      ? "Przejdź"
                      : "Zaloguj się"}
                  </span>{" "}
                  do serwisu.
                </p>
              </div>,
            )
          }
        }

        if (hasBeenAccept?.data?.message === "code not found") {
          toast.error(t("toast_verificationTemplate_error_code_not_found"))
        }
        if (
          hasBeenAccept?.data?.message === "You cannot have a second patient"
        ) {
          toast.error(
            t(
              "toast_verificationTemplate_error_you_cannot_have_second_patient",
            ),
          )
        }
        if (
          hasBeenAccept?.data?.message === "You cannot have a second guardian"
        ) {
          toast.error(
            t(
              "toast_verificationTemplate_error_you_cannot_have_second_guardian",
            ),
          )
        }
      } else if ("error" in hasBeenAccept) {
        const error = hasBeenAccept?.error as TranslationSubmitError

        switch (error?.data?.statusCode) {
          case 400:
            toast.error(t("toast_verificationTemplate_error"))
            break

          case 403:
            toast.error(t("toast_verificationTemplate_error_403"))
            break

          case 500:
            toast.error(t("toast_verificationTemplate_error_500"))
            break
        }
      }
    } else if (buttonClicked === "delete") {
      const hasBeenDeleted = await deleteUsersVerify({ data })
      if ("data" in hasBeenDeleted) {
        if (hasBeenDeleted?.data?.message === "code not found") {
          toast.error(t("toast_verificationTemplate_error_code_not_found"))
        }

        if (hasBeenDeleted?.data?.meta?.status === "success") {
          toast.success(t("toast_verificationTemplate_deleted"))
          setContent(
            <div
              className={`border alivia-radius border-1 border-primary fw-700 w-100 px-3 py-4 text-center mb-5`}
            >
              <p>
                Zaproszenie zostało{" "}
                <span className={"text-secondary"}>odrzucone</span>!
              </p>
              <p>
                Aby ponownie przyjąć zaproszenie poproś o ponowne przypisanie do
                konta.
              </p>
            </div>,
          )
        }

        if (hasBeenDeleted?.data?.statusCode === 403) {
          toast.error(t("toast_verificationTemplate_error_403"))
        }
      } else if ("error" in hasBeenDeleted) {
        const error = hasBeenDeleted?.error as TranslationSubmitError

        switch (error?.data?.statusCode) {
          case 400:
            toast.error(t("toast_verificationTemplate_error"))
            break

          case 403:
            toast.error(t("toast_verificationTemplate_error_403"))
            break

          case 500:
            toast.error(t("toast_verificationTemplate_error_500"))
            break
        }
      }
    }
  }

  return (
    <>
      <Landing isContent={false} isButton={watch("isEmail") && !isLogged} />
      <Container
        className={
          "d-flex flex-column align-items-center gap-4 my-4 pt-5 px-3 text-dark-blue"
        }
      >
        <h2 className={"fw-semibold ls-3"}>Akceptacja</h2>
        <p className={`text-center ${styles.content}`}>
          Twoje konto zostało dodane jako{" "}
          <span className={"fw-semibold"}>{textType}</span> dla innego konta w
          portalu Moja ALIVIA. Wybierz jedną z poniższych opcji (odrzucenie
          bezpowrotnie usunie zaproszenie):{" "}
        </p>
        <Form
          onSubmit={handleSubmit(submitHandler)}
          className={
            "d-flex flex-column flex-sm-row gap-3 gap-sm-5 w-100 justify-content-center"
          }
        >
          {content}
        </Form>
      </Container>
    </>
  )
}
import { Button, Form, FormGroup, FormLabel, Modal } from "react-bootstrap";
import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useGetMeQuery } from "redux/services/meApi";
import { useGetCancerQuery } from "redux/services/cancerApi";

interface ChatSupportFormModalProps {
  show: boolean;
  onHide: () => void;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isUserLoggedIn?: boolean;
}

interface FormData {
  name: string;
  email: string;
  subject: string;
  description: string;
  reason: string;
  orgid: string;
  retURL: string;
}

// const SUPPORT_REASONS = [
//   {
//     value: "",
//     label: "--Brak--",
//   },
//   {
//     value: "Czerwona skrzynka",
//     label: "Czerwona skrzynka",
//   },
//   {
//     value: "Skarbonka",
//     label: "Skarbonka",
//   },
//   {
//     value: "HUB",
//     label: "HUB",
//   },
// ];

const ChatSupportFormModal = ({
  show,
  setShow,
  onHide,
  isUserLoggedIn = true,
}: ChatSupportFormModalProps) => {

  const { data: meData, isLoading: isLoadingMe } = useGetMeQuery({}, { skip: !isUserLoggedIn });
  const { data: cancerData, isLoading: isLoadingCancer } = useGetCancerQuery(
    {
      id: meData?.data?.settings?.ID_nowotworu_HUB__c,
    },
    { skip: !meData?.data }
  );

  // const ChatSupportFormModal = ({
  //   show,
  //   setShow,
  //   onHide,
  // }: ChatSupportFormModalProps) => {
    // const { data: meData, isLoading: isLoadingMe } = useGetMeQuery({});
    // const { data: cancerData, isLoading: isLoadingCancer } = useGetCancerQuery({
    //   id: meData?.data?.settings?.ID_nowotworu_HUB__c,
    // });

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<FormData>({
    defaultValues: {
      name: meData?.data?.name || "",
      email: meData?.data?.email || "",
      subject: `${meData?.data?.email ? meData.data.email : ""}${cancerData?.data ? ` - ${cancerData.data.name}` : ""}${meData?.data?.email || cancerData?.data ? " - " : ""}Moja Alivia - nowe zapytanie`,
      description: "",
      reason: "HUB",
      orgid: "00D1t000000pZ8y",
      retURL: "http://",
    },
  });

  useEffect(() => {
    if (meData?.data) {
      setValue("name", meData.data.name);
      setValue("email", meData.data.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMe, isLoadingCancer]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    // data.reason = data.reason.value;
    try {
      const formData = new URLSearchParams();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      await fetch(
        "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D1t000000pZ8y",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "no-cors",
          body: formData,
        },
      );
    } catch (error) {
      console.error("Error:", error);
    }

    reset();
    setShow(false);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" style={{ zIndex: 10000 }}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="orgid" value="00D1t000000pZ8y" />
          <input type="hidden" name="retURL" value="http://" />

          {/*<FormGroup className={"my-2"}>*/}
          {/*  <FormLabel htmlFor={"name"}>Nazwa kontaktu</FormLabel>*/}
          {/*  <Form.Control*/}
          {/*    id="name"*/}
          {/*    {...register("name", {*/}
          {/*      maxLength: 80,*/}
          {/*    })}*/}
          {/*    type="text"*/}
          {/*    disabled*/}
          {/*  />*/}
          {/*</FormGroup>*/}

          <FormGroup className="my-2">
            <FormLabel htmlFor="email">Twój e-mail</FormLabel>
            <Form.Control
              id="email"
              type="email"
              {...register("email", {
                required: "Email jest wymagany.",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Proszę wprowadzić poprawny adres email.",
                },
                maxLength: {
                  value: 80,
                  message: "Email nie może przekraczać 80 znaków.",
                },
              })}
              isInvalid={!!errors.email}
              disabled={!!meData?.data}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email?.message}
            </Form.Control.Feedback>
          </FormGroup>

          {/* <FormGroup className={"my-2"}>
            <FormLabel htmlFor={"phone"}>Telefon</FormLabel>
            <Form.Control
              id="phone"
              maxLength={40}
              {...register("phone", {
                maxLength: 40,
              })}
              type="text"
            />
          </FormGroup> */}

          {/*<FormGroup className={"my-2"}>*/}
          {/*  <FormLabel htmlFor={"subject"}>Temat</FormLabel>*/}
          {/*  <Form.Control*/}
          {/*    id="subject"*/}
          {/*    {...register("subject", {*/}
          {/*      maxLength: 255,*/}
          {/*    })}*/}
          {/*    type="text"*/}
          {/*  />*/}
          {/*</FormGroup>*/}

          <FormGroup className="my-2">
            <FormLabel htmlFor="description">Treść wiadomości</FormLabel>
            <Form.Control
              as="textarea"
              id="description"
              rows={2}
              {...register("description", {
                required: "Treść wiadomości jest wymagana.",
              })}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </FormGroup>

          <Button type="submit" className="my-2 col-12">
            Prześlij
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChatSupportFormModal;

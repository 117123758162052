import StatuteBar from "components/molecules/StatuteBar/StatuteBar";
import AccountRoleWidget from "components/molecules/AccountRoleWidget/AccountRoleWidget";
import ChangePasswordModal from "components/molecules/ChangePasswordModal/ChangePasswordModal";
import DeleteAccountModal from "components/molecules/DeleteAccountModal/DeleteAccountModal";
import PopupModal from "components/molecules/PopupModal/PopupModal";
import RolesModal from "components/molecules/RolesModal/RolesModal";
import StatuteModal from "components/molecules/StatuteModal/StatuteModal";
import GuardiansAndPatientsPanel from "components/organisms/GuardiansAndPatientsPanel/GuardiansAndPatientsPanel";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Pencil } from "react-bootstrap-icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { RootState } from "redux/store/index";
import { useSelector } from "react-redux";
import { MeUser, UserRole } from "redux/Slice/user-slice";
import { useLazyGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import { toast } from "react-toastify";
import { SystemSetting } from "redux/Slice/system-settings-slice";
import { usePutUserRoleMutation } from "redux/services/usersApi";
import PageContainer from "components/atoms/PageContainer/PageContainer";
import DynamicPatientPoll from "components/organisms/DynamicPatientPoll/DynamicPatientPoll";
import styles from "./AccountSettingsTemplate.module.scss";
import { isRoleInUse } from "Helpers/Roles/isRoleInUse";
import { useTranslation } from "react-i18next";
import { AboutMeSettingsCard } from "components/molecules/AboutMeSettingsCard/AboutMeSettingsCard";
import { ContactInfoSettingsCard } from "components/molecules/ContactInfoSettingsCard/ContactInfoSettingsCard";
import { useGetAgreementLastUserQuery } from "redux/services/loyaltyProgramApi";
import { LastAcceptedAgreement } from "./types";
import {
  useAssignDoctorMutation,
  useLazyGetDoctorQuery,
  usePatchDoctorMutation,
  usePostDoctorMutation,
} from "redux/services/doctorApi";
const AccountSettingsTemplate = () => {
  const [patchMe, { isLoading: meLoading }] = usePatchMeMutation();
  const [putRole, { isLoading: putRoleLoading }] = usePutUserRoleMutation();
  const [postDoctor] = usePostDoctorMutation();
  const [updateDcotor] = usePatchDoctorMutation();
  const [assignDoctor] = useAssignDoctorMutation();
  const [getMe] = useLazyGetMeQuery();
  const [getDoctor] = useLazyGetDoctorQuery();
  const [modalText, setModalText] = useState("Hasło zostało zmienione");
  const [smallModalText, setSmallModalText] = useState("");
  const { data: lastAcceptedAgreement } = useGetAgreementLastUserQuery({});
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [modalContent, setModalContent] = useState<{
    loading: boolean;
    message?: string;
    error?: string;
    status?: string;
  }>({ loading: false });
  const { authUser, userRoles } = useSelector((state: RootState) => state.user);
  const { systemSettings } = useSelector(
    (state: RootState) => state.systemSettings
  );

  const { t } = useTranslation();

  const agreements =
    systemSettings &&
    systemSettings.filter(
      (systemSetting: SystemSetting) => systemSetting.type === "agreement"
    );

  const closeDoctorModal = async () => {
    await getMe({});
    setShowPopupModal(false);
  };

  const updatePoll = (roleName: string) => {
    window.scrollTo(0, 0);
    const setting =
      systemSettings &&
      systemSettings.find(
        (systemSetting: SystemSetting) => systemSetting?.context === roleName
      );
    if (setting) {
      setPollID(parseInt(setting.value));
      setShowPoll(true);
    }
  };

  const submitHandler: SubmitHandler<MeUser> = async (data) => {
    const userUpdated = await patchMe({
      data: {
        ...authUser,
        localSettings: {
          ...authUser?.localSettings,
          agree_contact_email: data?.settings?.agree_contact_email__c,
          agree_contact_phone: data?.settings?.agree_contact_phone__c,
        },
        settings: {
          ...authUser?.settings,
          agree_contact_email__c: data?.settings?.agree_contact_email__c,
          agree_contact_phone__c: data?.settings?.agree_contact_phone__c,
        },
      },
    });

    if ("data" in userUpdated) {
      toast.success(t("toast_accountSettingsTemplate_update_user_success"));
    } else if ("error" in userUpdated) {
      const error = userUpdated?.error as TranslationSubmitError;

      if (error.data.statusCode === 500) {
        toast.error(t("toast_accountSettingsTemplate_update_user_error_500"));
      }

      if (error.data.statusCode === 400) {
        toast.error(
          `${t("toast_accountSettingsTemplate_update_user_error_400")} ${
            error.data.message
          }`
        );
      }
    }

    setShowPersonalData(false);
    setShowContactData(false);
    setShowPopupModal(false);
    setshowDeleteAccountModal(false);
    setShowStatuteModal(false);
    setShowChangePasswordModal(false);
    setShowRolesModal(false);
  };

  const { register, handleSubmit } = useForm<MeUser>({
    defaultValues: {
      ...authUser,
    },
  });
  const [showPersonalData, setShowPersonalData] = useState(false);
  const [showContactData, setShowContactData] = useState(false);
  const [showPopupModal, setShowPopupModal] = useState(false);
  const [showDeleteAccountModal, setshowDeleteAccountModal] = useState(false);
  const [showStatuteModal, setShowStatuteModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [showPoll, setShowPoll] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [pollID, setPollID] = useState(0);
  const [isTextFromHTML, setIsTextFromHTML] = useState(false);

  const [statuteModalTitle, setStatuteModalTitle] = useState("");
  const [statuteModalText, setStatuteModalText] = useState("");

  const openStatuteModal = (
    title: string,
    text: string,
    isTextFromHTML: boolean
  ) => {
    setStatuteModalTitle(title);
    setStatuteModalText(text);
    setShowStatuteModal(true);
    setIsTextFromHTML(isTextFromHTML);
  };

  const displayPopupStatusText = (status?: string | undefined) => {
    switch (status) {
      case "sending":
        return "Trwa wysyłanie danych. Proszę czekać.";
      case "sent":
        return "Rola została dodana.";
      default:
        return modalContent?.error ?? "Błąd wysyłania";
    }
  };

  const setRole = async (data: RoleTileData) => {
    setModalContent({ loading: true, status: "sending" });
    setShowLoadingModal(true);
    const setting =
      systemSettings &&
      systemSettings.find(
        (systemSetting: SystemSetting) =>
          systemSetting?.context === data?.role?.role
      );
    try {
      const isRoleUpdated = await putRole({
        data: {
          userId: authUser.id,
          sendData: { roleId: data?.roleId?.toString() },
        },
      });

      if (data?.role?.role === "doctor") {
        if (!data?.doctorId) {
          const isDoctorAdded = await postDoctor({
            data: {
              firstName: data?.firstName || "",
              lastName: data?.lastName || "",
              description: "",
              phone: data?.telephone,
              email: authUser?.localSettings?.email,
              userId: authUser?.id,
              degreeId: data?.degreeId,
              specializationIds: data?.specializationIds || [],
            },
          });

          if ("data" in isDoctorAdded) {
          } else if ("error" in isRoleUpdated) {
            const error = isDoctorAdded?.error as TranslationSubmitError;

            if (error.data.statusCode === 500)
              toast.error(
                t("toast_accountSettingsTemplate_add_doctor_error_500")
              );

            if (error.data.statusCode === 400) toast.error(error.data.message);
            toast.error(t("toast_accountSettingsTemplate_add_doctor_error"));
          }
        } else {
          const fetchedDoctorData = await getDoctor(data?.doctorId);
          const isDoctorUpdated = await updateDcotor({
            id: data?.doctorId,
            data: {
              ...fetchedDoctorData?.data,
              phone: data?.telephone,
            },
          });

          if ("data" in isDoctorUpdated) {
          } else if ("error" in isRoleUpdated) {
            const error = isDoctorUpdated?.error as TranslationSubmitError;

            if (error.data.statusCode === 500)
              toast.error(
                t("toast_accountSettingsTemplate_update_doctor_error_500")
              );

            if (error.data.statusCode === 400) toast.error(error.data.message);
            toast.error(t("toast_accountSettingsTemplate_update_doctor_error"));
          }

          const isDoctorAssigned = await assignDoctor({
            id: data?.doctorId,
          });

          if ("data" in isDoctorAssigned) {
          } else if ("error" in isDoctorAssigned) {
            const error = isDoctorAssigned?.error as TranslationSubmitError;

            if (error.data.statusCode === 500)
              toast.error(
                t("toast_accountSettingsTemplate_assign_doctor_error_500")
              );

            if (error.data.statusCode === 400) toast.error(error.data.message);
            toast.error(t("toast_accountSettingsTemplate_assign_doctor_error"));
          }
        }
      }
      const findKey = userRoles.find(
        (role: Role) => role.role === data?.role?.role
      ) as Role;

      await patchMe({
        data: {
          ...authUser,
          settings: {
            ...authUser?.settings,
            [findKey?.sfName]: true,
          },
        },
      });

      if ("data" in isRoleUpdated) {
        if (data?.role?.role === "doctor") {
          setModalText("Dziękujemy!");
          setSmallModalText(
            "Poczekaj aż zweryfikujemy Twoje konto, aby móc przejść do edycji swojego profilu."
          );
          setShowLoadingModal(false);
          setShowPopupModal(true);
        } else {
          setShowLoadingModal(false);
          toast.success(t("toast_accountSettingsTemplate_add_role_success"));
          await getMe({});
        }
        setSelectedRole(data?.role?.role);
        if (data?.role?.role !== "patient") {
          setting && setPollID(parseInt(setting.value));
          setting?.value && setShowPoll(true);
        } else {
          authUser?.settings?.pollID__c &&
            setPollID(authUser?.settings?.pollID__c);
          authUser?.settings?.pollID__c && setShowPoll(true);
        }
      } else if ("error" in isRoleUpdated) {
        const error = isRoleUpdated?.error as TranslationSubmitError;

        if (error.data.statusCode === 500)
          toast.error(t("toast_accountSettingsTemplate_add_role_error_500"));

        if (error.data.statusCode === 400) toast.error(error.data.message);
        toast.error(t("toast_accountSettingsTemplate_add_role_error"));
      }
      if (!meLoading && !putRoleLoading) {
        setModalContent({
          loading: false,
          message: "Rola została dodana.",
          status: "sent",
        });
      }
    } catch (error) {
      setModalContent({
        loading: false,
        error: `Nie udało się dodać roli: ${error}`,
        status: "error",
      });
    }
    setShowRolesModal(false);
  };

  const pollSended = () => {
    if (selectedRole !== "patient") {
      patchMe({
        data: {
          ...authUser,
          localSettings: {
            ...authUser?.localSettings,
            [selectedRole + "_poll_Id"]: pollID,
          },
        },
      });
    } else {
      patchMe({
        data: {
          ...authUser,
          settings: {
            ...authUser?.settings,
            pollID__c: pollID,
          },
        },
      });
    }
    setShowPoll(false);
  };

  if (showPoll) {
    return (
      <PageContainer>
        <Container>
          <DynamicPatientPoll pollId={pollID} setIsPollSend={pollSended} />
        </Container>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Container fluid className={styles.AccountSettingsPage}>
        <h1 className="fw-600 dark text-dark-blue mb-5">
          Ustawienia Twojego konta
        </h1>
        <AboutMeSettingsCard
          authUser={authUser}
          setShow={setShowPersonalData}
          show={showPersonalData}
          className={styles.AccountSettingsWidget}
        />
        <ContactInfoSettingsCard
          authUser={authUser}
          setShow={setShowContactData}
          show={showContactData}
          className={styles.AccountSettingsWidget}
        />
        <Container fluid className={styles.AccountSettingsWidget}>
          <div className="w-100 p-4">
            <div className="justify-content-between d-flex flex-row align-items-center mb-5">
              <p className={`fw-600 fs-22 text-dark-blue m-0 ${styles.title}`}>
                Zmiana hasła
              </p>
              {authUser?.status === 1 && (
                <Button
                  variant="link"
                  className="text-dark-blue stroke-dark-blue"
                  onClick={() => setShowChangePasswordModal(true)}
                >
                  <Pencil size={20} />
                </Button>
              )}
            </div>
          </div>
        </Container>
        {authUser?.status === 1 && (
          <>
            {/* <Container fluid className={styles.AccountSettingsWidget}>
              <div className="w-100 p-4">
                <div className="justify-content-between d-flex flex-row align-items-center">
                  <p
                    className={`fw-600 fs-22 text-dark-blue m-0 ${styles.title}`}
                  >
                    Uprawnienia konta
                  </p>
                </div>
                {authUser?.roles
                  ?.filter((obj: UserRole) => obj?.role?.display === 1)
                  ?.map((userRole: UserRole, key: number) => (
                    <AccountRoleWidget
                      role={userRole?.role}
                      status={
                        typeof userRole?.status === "number"
                          ? userRole?.status
                          : parseInt(userRole?.status)
                      }
                      surveyButtonAction={updatePoll}
                      key={key}
                    />
                  ))}
                <Button
                  variant="outline-primary"
                  className="btn-outline-primary text-uppercase w-100 mt-4"
                  onClick={() => setShowRolesModal(true)}
                >
                  Dodaj nową rolę
                </Button>
              </div>
            </Container> */}
            {isRoleInUse("guardian", authUser) && (
              <GuardiansAndPatientsPanel
                text="Jeżeli bliska Ci osoba choruje na raka, zaproś ją do rejestracji w naszej Onkofundacji! Reprezentując ją, możesz pomóc jej w założeniu i edycji profilu, a także korzystać z dostępnych benefitów – zarówno w imieniu swoim, jak i zarejestrowanego bliskiego. Podaj adres e-mail chorującej na raka osoby, którą wspierasz, a my wyślemy jej zaproszenie do założenia konta. Jeśli jest już zarejestrowana, otrzyma powiadomienie o tym, że chcesz ją zaprezentować i zostanie poproszona o akceptację zaproszenia."
                type="patient"
                className={`${styles.AccountSettingsWidget} flex-column`}
              />
            )}
            {isRoleInUse("patient", authUser) && (
              <GuardiansAndPatientsPanel
                text="Dodaj bliską Ci osobę jako opiekuna i zapewnij mu uprawnienia do zarządzania wszystkimi informacjami powiązanymi z Twoich kontem. Dzięki temu staje się Twoim pełnomocnikiem, który będzie Cię wspierał na każdym etapie walki z chorobą. Pamiętaj, że Opiekun będzie miał wgląd we wszystkie Twoje dane, będzie mógł je edytować oraz korzystać z dostępnych w serwisie benefitów."
                type="guardian"
                className={`${styles.AccountSettingsWidget} flex-column`}
              />
            )}
          </>
        )}
        {agreements && (
          <Container fluid className={styles.AccountSettingsWidget}>
            <div className="w-100 p-4">
              <div className="justify-content-between d-flex flex-row align-items-center">
                <p
                  className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}
                >
                  Regulaminy
                </p>
              </div>
              {agreements &&
                agreements.map((agrement: SystemSetting, key: number) => (
                  <StatuteBar
                    title={t(agrement.context)}
                    type="statute"
                    text={agrement.value}
                    showStatuteModal={openStatuteModal}
                    isTextFromHTML={true}
                    key={key}
                  />
                ))}
              {lastAcceptedAgreement &&
                lastAcceptedAgreement?.data.map(
                  (agreement: LastAcceptedAgreement, key: number) => (
                    <StatuteBar
                      title={agreement.loyaltyProgramAgreement.name}
                      type="statute"
                      text={agreement.loyaltyProgramAgreement?.description}
                      showStatuteModal={openStatuteModal}
                      isTextFromHTML={true}
                      key={key}
                    />
                  )
                )}
            </div>
          </Container>
        )}
        {authUser?.status === 1 && (
          <Container fluid className={styles.AccountSettingsWidget}>
            <div className="w-100 p-4">
              <div className="justify-content-between d-flex flex-wrap flex-row align-items-center">
                <p
                  className={`fw-600 fs-22 text-dark-blue mb-5 ${styles.title}`}
                >
                  Zgody
                </p>
                <p
                  className="fw-400 fs-16 text-dark-blue"
                  dangerouslySetInnerHTML={{
                    __html: t("agreements_rodo_subtext", {
                      interpolation: { escapeValue: false },
                    }),
                  }}
                ></p>
              </div>
              <StatuteBar
                defaultChecked={authUser?.settings?.agree_contact_email__c}
                title="Zgoda na newsletter marketingowy"
                type="agreement"
                showStatuteModal={openStatuteModal}
                register={register("settings.agree_contact_email__c", {
                  onChange: handleSubmit(submitHandler),
                })}
              />
              <StatuteBar
                defaultChecked={authUser?.settings?.agree_contact_phone__c}
                title="Zgoda na marketing telefoniczny"
                type="agreement"
                showStatuteModal={openStatuteModal}
                register={register("settings.agree_contact_phone__c", {
                  onChange: handleSubmit(submitHandler),
                })}
              />
              {/* Powiadomienia push zostały przeniesione na rozwój, dlatego teraz jest to ukryte */}
              {/* <StatuteNotificationBar
                handleSubmit={handleSubmit(submitHandler)}
                setValue={setValue}
              /> */}
            </div>
          </Container>
        )}
        <Container fluid className={styles.AccountSettingsWidget}>
          <div className="w-100 p-4">
            <div className="justify-content-between d-flex flex-row align-items-center">
              <p className="fw-600 fs-22 text-dark-blue mb-5">Usuń konto</p>
            </div>
            <Button
              variant="outline-primary"
              className="text-uppercase btn-outline-primary w-100"
              onClick={() => setshowDeleteAccountModal(true)}
            >
              Usuń konto
            </Button>
          </div>
        </Container>
      </Container>
      <PopupModal
        show={showPopupModal}
        type="info"
        titleClasses="text-center fw-600 fs-16"
        text={modalText}
        smallText={smallModalText}
        setShow={closeDoctorModal}
      />
      <DeleteAccountModal
        show={showDeleteAccountModal}
        setShow={setshowDeleteAccountModal}
      />
      <StatuteModal
        title={statuteModalTitle}
        show={showStatuteModal}
        setShow={setShowStatuteModal}
        text={statuteModalText}
        isTextFromHTML={isTextFromHTML}
      />
      <ChangePasswordModal
        show={showChangePasswordModal}
        setShow={setShowChangePasswordModal}
      />
      <RolesModal
        show={showRolesModal}
        setShow={setShowRolesModal}
        setRole={setRole}
      />
      <PopupModal
        show={showLoadingModal}
        setShow={setShowLoadingModal}
        text={displayPopupStatusText(modalContent?.status)}
        loading={modalContent.loading}
        type="info"
        confirmAction={() => setShowLoadingModal(false)}
        titleClasses="fw-600 text-center"
        hideHeader={true}
      />
    </PageContainer>
  );
};

export default AccountSettingsTemplate;

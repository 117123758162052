import { logOutImpersonate, users } from "endpoints/endpoints";
import { apiSlice } from "./apiSlice";
import { SFContact, User } from "redux/Slice/user-slice";

export enum UserVerifyTypeEnum {
  COMPANY_USER = "COMPANY_USER",
  PATIENT_GUARDIAN = "PATIENT_GUARDIAN",
  GUARDIAN_PATIENT = "GUARDIAN_PATIENT",
  DOCTOR_FACILITY = "DOCTOR_FACILITY",
  FACILITY_DOCTOR = "FACILITY_DOCTOR",
}

interface DeleteGuardianOrPatientProps {
  data: {
    type: "GUARDIAN" | "PATIENT";
    userId?: number;
    authId?: string;
    email?: string;
  };
}

export interface UsersProps {
  offset?: string;
  limit?: string;
  authId?: string;
  userId?: number;
  nameLike?: string;
  status?: string;
  enabled?: string;
  deleted?: string;
  roleId?: string;
  sortBy?: string;
  email?: string;
}

interface PatchDeleteVerificationProps {
  data: {
    code: string;
    type: string;
  };
}

interface PutUserRoleProps {
  data: {
    userId: number;
    sendData: {
      roleId: string;
    };
  };
}

export interface VerificationData {
  id?: number | string | undefined;
  type: UserVerifyTypeEnum;
  email: string;
  languageCode?: string;
}

interface PostVerificationProps {
  data: VerificationData;
}
interface PatchStatusAndDeletedProps {
  data: {
    userId: number;
    deleted?: number;
    status?: number;
    enabled?: number;
  };
}
interface PatchActiveUserProps {
  data: {
    userId: number;
    enabled?: number;
  };
}

interface PatchUserSFContact {
  data: Partial<SFContact>;
  userId: string;
}
interface PostResetPasswordProps {
  userId: number;
}

interface DeleteGuardianOrPatientPayload {
  id: string;
  data: {
    idType: "userId" | "salesforceId" | "authId";
    relationshipType: "guardian" | "patient" | "both";
  };
}

interface DeleteGuardianOrPatientResponse {
  user: User;
  guardianUser: User;
  patientUser: User;
}

interface SendChangePasswordWithAdmin {
  data: {
    newPassword: string;
    newPasswordRepeat?: string;
  };
}

const apiWithTags = apiSlice.enhanceEndpoints({
  addTagTypes: ["Users"],
});

export const usersApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: ({
        offset,
        limit,
        nameLike,
        status,
        deleted,
        roleId,
        sortBy,
        email,
        authId,
        enabled,
      }: UsersProps) => {
        const sortArray = sortBy?.split(",");
        const params = new URLSearchParams({
          ...(authId && { authId }),
          ...(offset && { offset }),
          ...(limit && { limit }),
          ...(nameLike && { nameLike }),
          ...(status && { status }),
          ...(deleted && { deleted }),
          ...(roleId && { roleId }),
          ...(email && { email }),
          ...(sortArray && { sortBy: sortArray[0] }),
          ...(sortArray && { sortOrder: sortArray[1] }),
          ...(enabled && { enabled }),
        });
        return {
          url: `${users}?${params.toString()}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Users"],
    }),

    getUserGuardiansAndPatients: builder.query({
      query: () => {
        return {
          url: `${users}/guardian-and-patient`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
      providesTags: ["Users"],
    }),

    deleteUserGuardiansAndPatients: builder.mutation({
      query: ({ data }: DeleteGuardianOrPatientProps) => {
        return {
          url: `${users}/guardian-and-patient`,
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: data,
        };
      },
      invalidatesTags: ["Users"],
    }),

    putUserRole: builder.mutation({
      query: ({ data }: PutUserRoleProps) => ({
        url: `${users}/${data.userId.toString()}/roles`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: data.sendData,
      }),
      invalidatesTags: ["Users"],
    }),

    deleteUserRole: builder.mutation({
      query: ({ data }: PutUserRoleProps) => ({
        url: `${users}/${data.userId.toString()}/roles/${data.sendData.roleId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: data.sendData,
      }),
      invalidatesTags: ["Users"],
    }),

    patchActiveUserEmail: builder.mutation({
      query: ({ data }: PatchActiveUserProps) => ({
        url: `${users}/${data.userId.toString()}/active-user`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Users"],
    }),

    postUsersVerify: builder.mutation({
      query: ({ data }: PostVerificationProps) => ({
        url: `${users}/verify`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    patchStatusAndDeleted: builder.mutation({
      query: ({ data }: PatchStatusAndDeletedProps) => ({
        url: `${users}/status-deleted`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    patchUsersVerify: builder.mutation({
      query: ({ data }: PatchDeleteVerificationProps) => ({
        url: `${users}/verify`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    patchUsersVerifyLogged: builder.mutation({
      query: ({ data }: PatchDeleteVerificationProps) => ({
        url: `${users}/verify-logged`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    resetUserPassword: builder.mutation({
      query: ({ userId }: PostResetPasswordProps) => ({
        url: `${users}/${userId}/reset-password`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Users"],
    }),

    deleteUsersVerify: builder.mutation({
      query: ({ data }: PatchDeleteVerificationProps) => ({
        url: `${users}/verify`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${users}/${userId}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Users"],
    }),

    patchUserSFContact: builder.mutation({
      query: ({ data, userId }: PatchUserSFContact) => ({
        url: `${users}/${userId}/sf-contact`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),

    getUser: builder.query({
      query: ({ userId }: { userId: string }) => ({
        url: `${users}/${userId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Users"],
    }),

    getSfContact: builder.query({
      query: ({ userId }: { userId: string }) => ({
        url: `${users}/${userId}/sf-contact`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Users"],
    }),

    postImpersonate: builder.mutation({
      query: ({ userId }: { userId: string }) => ({
        url: `${users}/${userId}/impersonate`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Users"],
    }),

    postLogOutImpersonate: builder.mutation({
      query: () => ({
        url: `${logOutImpersonate}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["Users"],
    }),

    getUserFromSfId: builder.query({
      query: ({ userSfId }: { userSfId: string }) => ({
        url: `${users}/salesforce-id/${userSfId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      providesTags: ["Users"],
    }),

    deleteGuardianOrPatientFromId: builder.mutation<
      DeleteGuardianOrPatientResponse,
      DeleteGuardianOrPatientPayload
    >({
      query: ({ id, data }) => ({
        url: `${users}/${id}/guardian-or-patient`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
    changePasswordWithAdmin: builder.mutation({
      query: ({ userId, data }) => ({
        url: `${users}/${userId}/change-password`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: data,
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  usePatchStatusAndDeletedMutation,
  usePatchActiveUserEmailMutation,
  usePostUsersVerifyMutation,
  usePatchUsersVerifyMutation,
  usePatchUsersVerifyLoggedMutation,
  useDeleteUsersVerifyMutation,
  usePutUserRoleMutation,
  useDeleteUserRoleMutation,
  useGetUserGuardiansAndPatientsQuery,
  useDeleteUserGuardiansAndPatientsMutation,
  useResetUserPasswordMutation,
  usePatchUserSFContactMutation,
  useGetSfContactQuery,
  usePostImpersonateMutation,
  usePostLogOutImpersonateMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useLazyGetUserFromSfIdQuery,
  useLazyGetSfContactQuery,
  useDeleteGuardianOrPatientFromIdMutation,
  useChangePasswordWithAdminMutation,
} = usersApi;

import React, { useState, useCallback } from "react";
import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";

import { setForceSend } from "redux/Slice/messages-slice";
import {
  useGetTranslationsByLangIdQuery,
} from "redux/services/translationsApi";
import { useGetMeQuery, usePatchMeMutation } from "redux/services/meApi";
import {
  usePatchStatusAndDeletedMutation,
  usePatchUserSFContactMutation,
} from "redux/services/usersApi";
import { useSendMailTemplateMutation } from "redux/services/emailsApi";

import { useOpenHelpTopicCustomMessage } from "Helpers/openMessageHooks";
import { logoutHandler } from "Helpers/logoutHandler";
import { useDeleteGuardianAndPatient } from "Helpers/useDeleteGuardianAndPatient";

const LANGUAGE = {
  POLISH: {
    ID: 1,
    CODE: "pl",
  },
};

const OTHER_TOPIC_ID = 6;
const OTHER_TOPIC_CASE = "Inne";
const STATUS_TOPIC_VISIBLE = 1;
const ACCOUNT_DELETION_EMAIL_KEY = "accountDelete";
const ACCOUNT_TO_BE_DELETED_STATUS = 90;

interface DeleteAccountModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FilteredTranslation {
  id: string;
  title: string;
}

interface DeleteData {
  reason: string;
}

interface SfKeyToDeleteAccount {
  Do_usuni_cia__c?: boolean;
  Do_usuni_cia_all__c?: boolean;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ show, setShow }) => {
  const dispatch = useDispatch();

  const [showDeleteContent, setShowDeleteContent] = useState<boolean>(false);
  const [selectedReasonTitle, setSelectedReasonTitle] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { data: translationsData } = useGetTranslationsByLangIdQuery({
    languageId: LANGUAGE.POLISH.ID,
  });

  const { data: userData } = useGetMeQuery({});

  const [sendMailTemplate] = useSendMailTemplateMutation();
  const [changeAccountStatus] = usePatchStatusAndDeletedMutation();
  const [patchSfAccount] = usePatchUserSFContactMutation();
  const [patchUserSettings] = usePatchMeMutation();

  const deleteGuardianAndPatient = useDeleteGuardianAndPatient();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<DeleteData>({
    defaultValues: {
      reason: "",
    },
  });

  const filteredTranslations: FilteredTranslation[] = React.useMemo(() => {
    if (!translationsData?.data) return [];
    return Object.keys(translationsData.data)
      .filter((key) => /^delete_account_reason_\d+$/.test(key))
      .map((key) => ({
        id: key,
        title: translationsData.data[key],
      }));
  }, [translationsData]);

  const currentUserData = userData?.data;

  const emailDataToSend = React.useMemo(
    () => ({
      emailKey: ACCOUNT_DELETION_EMAIL_KEY,
      email: currentUserData?.email || "",
      sendToAlivia: false,
      languageCode: LANGUAGE.POLISH.CODE,
      replaceArray: [""],
    }),
    [currentUserData]
  );

  const openHelpTopicCustomMessage = useOpenHelpTopicCustomMessage({
    topic: {
      id: OTHER_TOPIC_ID,
      case: OTHER_TOPIC_CASE,
      status: STATUS_TOPIC_VISIBLE,
    },
    customMessage: selectedReasonTitle,
  });

  const onSubmit: SubmitHandler<DeleteData> = useCallback(
    async (deleteData) => {
      setLoading(true);
      setError(null);
      try {
        let sfKeyToDeleteAccount: SfKeyToDeleteAccount;
        switch (deleteData.reason) {
          case "delete_account_reason_01":
            sfKeyToDeleteAccount = { Do_usuni_cia__c: true };
            break;
          case "delete_account_reason_02":
            sfKeyToDeleteAccount = { Do_usuni_cia_all__c: true };
            break;
          default:
            sfKeyToDeleteAccount = { Do_usuni_cia__c: true };
        }

        await patchSfAccount({
          userId: `${currentUserData?.id}`,
          data: sfKeyToDeleteAccount,
        });

        const patchSettingsData = {
          settings: { ...currentUserData?.settings, ...sfKeyToDeleteAccount },
        };
        await patchUserSettings({ data: patchSettingsData });

        const { Wskazanie_na_opiekuna__c, Wskazanie_podopiecznego__c } = currentUserData?.settings || {};
        if (Wskazanie_na_opiekuna__c && Wskazanie_podopiecznego__c) {
          await deleteGuardianAndPatient(currentUserData.id, "both");
        } else if (Wskazanie_na_opiekuna__c) {
          await deleteGuardianAndPatient(currentUserData.id, "guardian");
        } else if (Wskazanie_podopiecznego__c) {
          await deleteGuardianAndPatient(currentUserData.id, "patient");
        }

        await openHelpTopicCustomMessage();
        dispatch(setForceSend(true));

        await sendMailTemplate(emailDataToSend).unwrap();

        await changeAccountStatus({
          data: {
            userId: currentUserData?.id || "",
            status: ACCOUNT_TO_BE_DELETED_STATUS,
          },
        });

        reset();
        setShowDeleteContent(true);
      } catch (error: any) {
        console.error("Error deleting account:", error);
        setError("Wystąpił błąd podczas usuwania konta. Spróbuj ponownie później.");
      } finally {
        setLoading(false);
      }
    },
    [
      patchSfAccount,
      currentUserData,
      patchUserSettings,
      deleteGuardianAndPatient,
      openHelpTopicCustomMessage,
      dispatch,
      sendMailTemplate,
      emailDataToSend,
      changeAccountStatus,
      reset,
    ]
  );

  const DeleteContent: React.FC = () => (
    <div>
      <p>Twoje konto zostanie usunięte w przeciągu 7 dni.</p>
      <Button variant="primary" onClick={logoutHandler} disabled={loading}>
        OK
      </Button>
    </div>
  );

  const AccountDeletionForm: React.FC = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {filteredTranslations.map((reason) => (
        <Form.Check
          key={reason.id}
          type="radio"
          label={reason.title}
          value={reason.id}
          {...register("reason", { required: true })}
          onClick={() => setSelectedReasonTitle(reason.title)}
          className="mb-3"
          disabled={loading}
        />
      ))}
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="d-flex justify-content-end align-items-center">
        <Button
          variant="outline-dark"
          onClick={() => {
            reset();
            setShow(false);
          }}
          disabled={loading}
        >
          Anuluj
        </Button>
        <Button
          disabled={!isDirty || loading}
          variant="danger"
          type="submit"
          className="ms-3"
        >
          {loading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />{" "}
              Usuwanie...
            </>
          ) : (
            "Usuń konto"
          )}
        </Button>
      </div>
    </Form>
  );

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Usuwanie konta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showDeleteContent ? <DeleteContent /> : <AccountDeletionForm />}
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAccountModal;
import { useGetUserRolesQuery } from "redux/services/userRolesApi";
import RoleTile from "../RoleTile/RoleTile";
import { RootState } from "redux/store/index";
import { useSelector } from "react-redux";
import { isRoleInUse } from "Helpers/Roles/isRoleInUse";

interface RolesDisplayProps {
  setRole?: (data: RoleTileData) => void;
}

const RolesDisplay = ({ setRole }: RolesDisplayProps) => {
  const { authUser } = useSelector((state: RootState) => state.user);
  const { data: fetchedRoles } = useGetUserRolesQuery({
    filterBy: "display",
  });
  const userRoles = {
    userRoles: fetchedRoles?.userRoles?.filter(
      (role: Role) => role?.role !== "doctor"
    ),
  };
  const showRoles = () => {
    let newArray = [];
    if (userRoles) {
      for (let i = 0; i < userRoles?.userRoles?.length; i = i + 2) {
        if (
          i !== userRoles?.userRoles?.length - 1 &&
          i !== userRoles?.userRoles?.length - 2
        ) {
          newArray.push(
            <div className="row gap-4 mx-2 mb-4" key={i}>
              <div className="col-lg p-0">
                <RoleTile
                  role={userRoles?.userRoles[i]}
                  setRole={setRole}
                  disabled={isRoleInUse(userRoles?.userRoles[i], authUser)}
                />
              </div>
              <div className="col-lg p-0">
                <RoleTile
                  role={userRoles?.userRoles[i + 1]}
                  setRole={setRole}
                  disabled={isRoleInUse(userRoles?.userRoles[i + 1], authUser)}
                />
              </div>
            </div>
          );
        } else {
          if (userRoles?.userRoles?.length % 2 === 0) {
            newArray.push(
              <div className="row gap-4 mx-2" key={i}>
                <div className="col-lg p-0">
                  <RoleTile
                    role={userRoles?.userRoles[i]}
                    setRole={setRole}
                    disabled={isRoleInUse(userRoles?.userRoles[i], authUser)}
                  />
                </div>
                <div className="col-lg p-0">
                  <RoleTile
                    role={userRoles?.userRoles[i + 1]}
                    setRole={setRole}
                    disabled={isRoleInUse(
                      userRoles?.userRoles[i + 1],
                      authUser
                    )}
                  />
                </div>
              </div>
            );
          }
          // else {
          //   newArray.push(
          //     <div className="row gap-4 mx-2" key={i}>
          //       <div className="col-lg p-0">
          //         <RoleTile
          //           role={userRoles?.userRoles[i]}
          //           setRole={setRole}
          //           disabled={isRoleInUse(userRoles?.userRoles[i], authUser)}
          //         />
          //       </div>
          //     </div>
          //   );
          // }
        }
      }
    }
    return newArray;
  };

  return <div>{showRoles()}</div>;
};

export default RolesDisplay;
